import { HomeOutlined, BookOutlined, InboxOutlined, TeamOutlined, InsertRowBelowOutlined, SettingOutlined,FileDoneOutlined, ShopOutlined, AppstoreOutlined, CopyrightOutlined, FormOutlined, DollarOutlined, MenuOutlined } from '@ant-design/icons';
import { Menu, Layout, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const { Sider } = Layout;

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

function MenuSideBars() {
    const selector = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false); // State untuk mengelola keterbukaan Sider
    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        var user_role = JSON.parse(selector.menu);
        var menu = [];
        for (let i = 0; i < user_role.user_role_menus.length; i++) {
            var mn = user_role.user_role_menus[i];
            var sub_mn = [];
            if (mn.user_role_submenus.length) {
                for (let i2 = 0; i2 < mn.user_role_submenus.length; i2++) {
                    var sub = mn.user_role_submenus[i2];
                    if (sub.user_role_submenu_ !== null) {
                        sub_mn.push(getItem(sub.submenu.submenu, sub.submenu.link));
                    }
                }
            }
            if (sub_mn.length > 0)
                menu.push(getItem(mn.menu.menu, mn.menu.link, getIcon(mn.menu.icon), sub_mn));
            else
                menu.push(getItem(mn.menu.menu, mn.menu.link, getIcon(mn.menu.icon)));
        }

        menu.push(getItem('Pengaturan', 'setting', getIcon('settings'), [
            getItem('Printer', 'printer'),
            // getItem('Import Data', 'importFile'),
        ]))
        setMenuItems(menu);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getIcon = (icon_name) => {
        switch (icon_name) {
            case 'home':
                return <HomeOutlined />;
            case 'pos':
                return <ShopOutlined />;
            case 'setoutletproduct':
                return <InsertRowBelowOutlined />;
            case 'master':
                return <AppstoreOutlined />;
            case 'report':
                return <BookOutlined />;
            case 'team':
                return <TeamOutlined />;
            case 'point':
                return <CopyrightOutlined />;
            case 'settings':
                return <SettingOutlined />;
            case 'membership':
                return <TeamOutlined />
            case 'verify':
                return <FormOutlined />
            case 'penjualan':
                return <InboxOutlined />
            case 'promo':
                return <DollarOutlined />
            case 'selling':
                return <FileDoneOutlined />
            default:
                return null;
        }
    }

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    }

    return (
        <Sider
            // collapsible
            collapsed={collapsed}
            onCollapse={setCollapsed}
            width={200}
            collapsedWidth={80} // Lebar Sider saat diperkecil
            style={{
                backgroundColor: '#ffffff', // Latar belakang putih
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', // Optional: tambahkan shadow
            }}
        >
            <div className="logo" />
            <Button
                type="text"
                icon={collapsed ? <MenuOutlined style={{ color: '#1890ff' }} /> : <MenuOutlined style={{ color: '#1890ff' }} />}
                onClick={toggleCollapsed}
                style={{ marginLeft:24,marginTop:10,textAlign: 'center' }}
            />
            <Menu
                mode="inline"
                defaultSelectedKeys={['dashboard']}
                style={{
                    height: '100%',
                    borderRight: 0,
                    fontSize: 11,
                    backgroundColor: '#ffffff', // Optional: latar belakang putih untuk Menu
                }}
                items={menuItems}
                onClick={({ key }) => navigate('/' + key)}
            />
        </Sider>
    );
}

export default MenuSideBars;
