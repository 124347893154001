import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { dateFormat } from '../formatDate';

export const exportToExcelTransaction = async (data) => {
    // Mengonversi data dengan nama field baru
    let mappedData = [];

    data.forEach(item => {
        if (item.transaction_products.length > 0) {
            item.transaction_products.forEach(product => {
                let product_name = product.product.product_name;
                let price_after_discount = Number(product.discount.price_after_discount);
                let qty = Number(product.qty);
                let discount_percent = Number(product.discount.discount_percent);
                let discount_percent_2 = Number(product.discount.discount_percent_2);
                let subtotal = Number(product.subtotal);
                let price_normal = Number(price_after_discount) + Number(product.discount.discount_price);
                let voucherNum = 0;
                if (item.voucher) {
                    voucherNum = Number(item.voucher.discount_price);
                } else {
                    voucherNum = Number(item.voucher_price);
                }
                mappedData.push({
                    'Kode Transaksi': item.transaction_code,
                    'Tanggal': dateFormat(item.created_at),
                    'Nama Member': item.customer !== null ? item.customer.name : null,
                    'Outlet': item.outlet.outlet_name,
                    'Gudang': item.transaction_products[0].warehouse ? item.transaction_products[0].warehouse.warehouse_name : null,
                    'Nama Barang': product_name,
                    'Harga Normal': price_normal,
                    'Diskon 1 (%)': discount_percent,
                    'Diskon 2 (%)': discount_percent_2,
                    'Harga Setelah Diskon': price_after_discount,
                    'Qty': qty,
                    'Sub Total': subtotal,
                    'Voucher': voucherNum,
                    'Point': item.point !== null ? Number(item.point.point) : null,
                    'Tukar Point': item.point_redeem !== null ? Number(item.point_redeem) : null,
                    'Pembayaran': item.payment_method === 'tunai' ? 'TUNAI' : item.device_payment
                });
            });
        }
    });

    // Hitung total dari semua subtotal
    const totalSubTotal = mappedData.reduce((acc, item) => acc + (Number(item['Sub Total']) || 0), 0);

    // Hitung total qty dari semua transaksi
    const totalQty = mappedData.reduce((acc, item) => acc + (Number(item['Qty']) || 0), 0);
    const totalPoint = mappedData.reduce((acc, item) => acc + (Number(item['Point']) || 0), 0);
    const totalPointRedeem = mappedData.reduce((acc, item) => acc + (Number(item['Tukar Point']) || 0), 0);

    // Membuat worksheet Excel
    const worksheet = XLSX.utils.json_to_sheet(mappedData);

    // Menyesuaikan panjang kolom
    const columnWidths = getColumnWidths(mappedData);
    worksheet['!cols'] = columnWidths;

    // Menambahkan total di akhir sheet
    const totalRowIndex = mappedData.length + 2; // Baris terakhir + 1 untuk header
    const grandTotalRow = [
        'Grand Total', '', '', '', '', '', '', '', '', '', totalQty, totalSubTotal, '', totalPoint, totalPointRedeem
    ];
    XLSX.utils.sheet_add_aoa(worksheet, [grandTotalRow], { origin: { r: totalRowIndex, c: 0 } });

    // Menjadikan grand total sejajar dengan kolom 'Sub Total'
    const subTotalColumnIndex = Object.keys(mappedData[0]).indexOf('Sub Total');
    const grandTotalCell = XLSX.utils.encode_cell({ r: totalRowIndex, c: subTotalColumnIndex });
    worksheet[grandTotalCell] = { v: totalSubTotal, t: 'n' }; // 't: n' untuk nilai numerik

    // Membuat workbook dan menyimpan sebagai file Excel
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'data.xlsx');
};

// Fungsi untuk menghitung panjang maksimum teks di setiap kolom
function getColumnWidths(data) {
    const maxWidth = 50; // Lebar maksimum kolom
    const columnWidths = [];

    // Iterasi untuk setiap kolom
    for (const item of data) {
        Object.keys(item).forEach((key, index) => {
            const columnLength = item[key] ? String(item[key]).length : 0;
            columnWidths[index] = columnWidths[index] || 0;
            if (columnLength > columnWidths[index] && columnLength < maxWidth) {
                columnWidths[index] = columnLength;
            }
        });
    }

    // Mengubah lebar kolom ke format yang dapat diterima oleh xlsx
    return columnWidths.map(width => ({ width: width > maxWidth ? maxWidth : width }));
}
