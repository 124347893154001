
import { Alert, Button, Checkbox, Col, Form, Input, InputNumber, Modal, Radio, Row, Select, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined, SyncOutlined, LoadingOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
import { currencyFormat } from '../../Utils/formatCurrency';


function ListMemberDisc() {
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [search, setSearch] = useState('');
    const [discountType, setDiscountType] = useState('percent');
    const [isFixed, setIsFixed] = useState(false);
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [form] = Form.useForm();
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);
    const [discountScope, setDiscountScope] = useState("all");
    const [categoriesAll, setCategoriesAll] = useState([]);
    const [productsAll, setProductsAll] = useState([]);
    const [outletsAll, setOutletsAll] = useState([]);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [outlets, setOutlets] = useState([]);

    // //Parameter
    const titlePage = 'Member Cashback';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => {
                ++index;
                return (page.pagination.current - 1) * 10 + index;
            },
        },
        {
            title: 'Nama Member Cashback',
            dataIndex: 'name',
            key: "name",
        },
        {
            title: 'Cashback',
            dataIndex: 'discount',
            key: "discount",
            render: (text, record) => {
                if (record.cashback_point_percent !== null) {
                    return `${record.cashback_point_percent}%`;
                } else if (record.cashback_point_price !== null) {
                    return currencyFormat(record.cashback_point_price);
                } else {
                    return 'Tidak ada diskon';
                }
            },
        },
        {
            title: 'Masa Berlaku',
            dataIndex: 'expired',
            key: "expired",
            render: (text, record) => {
                if (record.is_status_fixed) {
                    return 'Tetap';
                } else if (record.end_date) {
                    return new Date(record.end_date).toLocaleDateString();
                } else {
                    return 'Tidak ditentukan';
                }
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('edit', record)}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        const isStatusFixed = form.getFieldValue('is_status_fixed');
        form.setFieldsValue({ is_status_fixed: !!isStatusFixed });
        getApi('/memberdisc?search=' + search + '&page=' + page.pagination.current, 'member');
        getApi('/category?nopage=true', 'category');
        getApi('/product?nopage=true', 'product');
        getApi('/outlet?nopage=true', 'outlet');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (url, type) => {
        setLoading(true);
        const dataReq = {
            method: "GET",
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'member':
                        setData(res.data.data);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.total_data
                            }
                        });
                        setLoading(false);
                        setLoadingBtn(false);
                        break;
                    case 'category':
                        setCategoriesAll(res.data);
                        setCategories(res.data);
                        setLoading(false);
                        setLoadingBtn(false);
                        break;
                    case 'product':
                        setProductsAll(res.data.rows);
                        setProducts(res.data.rows);
                        setLoading(false);
                        setLoadingBtn(false);
                        break;

                    case 'outlet':
                        setOutletsAll(res.data);
                        setOutlets(res.data);
                        setLoading(false);
                        setLoadingBtn(false);
                        break;

                    default:
                        setLoading(false);
                        setLoadingBtn(false);
                        break;
                }
            } else {
                setLoading(false);
                setLoadingBtn(false);
            }
        }).catch((e) => {
            setLoading(false);
            setLoadingBtn(false);
        })
    }

    const postApi = (method, values) => {
        setLoading(true);
        setIsAlert(false);
        var url = '/memberdisc';
        if (method === 'DELETE' || method === 'PUT') {
            url = '/memberdisc/' + values.member_disc_id;
        }
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setdataModal(null);
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', v),
        });
    }

    const openModal = (type, data) => {
        if (type === 'edit') {
            console.log(data)
            setdataModal(data);
            setDiscountScope('all');
            var arrCat = [];
            var arrProd = [];
            var arrOut = [];
            if (data.member_category_discs.length > 0) {
                setDiscountScope('category');
                for (let i = 0; i < data.member_category_discs.length; i++) {
                    const item = data.member_category_discs[i];
                    arrCat.push(item.category_id);
                }
            }

            if (data.member_product_discs.length > 0) {
                setDiscountScope('product');
                for (let i = 0; i < data.member_product_discs.length; i++) {
                    const item = data.member_product_discs[i];
                    arrProd.push(item.product_id);
                }
            }


            if (data.member_outlet_discs.length > 0) {
                setDiscountScope('product');
                for (let i = 0; i < data.member_outlet_discs.length; i++) {
                    const item = data.member_outlet_discs[i];
                    arrOut.push(item.outlet_id);
                }
            }

            form.setFieldsValue({
                name: data.name,
                description: data.description,
                cashback_point_percent: data.cashback_point_percent,
                cashback_point_price: data.cashback_point_price,
                start_date: data.start_date,
                end_date: data.end_date,
                start_time: data.start_time,
                end_time: data.end_time,
                is_status_fixed: data.is_status_fixed,
                category: arrCat,
                product: arrProd,
                outlet: arrOut,
            });
            setIsFixed(data.is_status_fixed);
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    };

    const onFinish = (ev) => {
        var arrCat = [];
        var arrProd = [];
        var arrOut = [];
        var is_global = false;
        var is_category = false;
        var is_product = false;


        if (discountScope === 'all') {
            is_global = true;
        } else if (discountScope === 'category') {
            is_category = true;
        } else if (discountScope === 'product') {
            is_product = true;
        }

        if (ev.category !== undefined) {
            for (let i = 0; i < ev.category.length; i++) {
                const item = ev.category[i];
                arrCat.push({
                    category_id: item
                });
            }
        }

        if (ev.product !== undefined) {
            for (let i = 0; i < ev.product.length; i++) {
                const item = ev.product[i];
                arrProd.push({
                    product_id: item
                });
            }
        }


        if (ev.outlet !== undefined) {
            for (let i = 0; i < ev.outlet.length; i++) {
                const item = ev.outlet[i];
                arrOut.push({
                    outlet_id: item
                });
            }
        }

        const data = {
            name: ev.name,
            description: ev.description,
            cashback_point_percent: ev.cashback_point_percent || null,
            cashback_point_price: ev.cashback_point_price || null,
            start_date: ev.start_date || null,
            end_date: ev.end_date || null,
            start_time: ev.start_time || null,
            end_time: ev.end_time || null,
            is_status_fixed: ev.is_status_fixed || false,
            category: arrCat,
            product: arrProd,
            outlet:arrOut,
            is_global: is_global,
            is_category: is_category,
            is_product: is_product
        };
        // console.log(data)
        if (dataModal != null) {
            data.member_disc_id = dataModal.member_disc_id;
            postApi('PUT', data);
        } else {
            postApi('POST', data);
        }
    };

    const closeModal = () => {
        setdataModal(null);
        setIsModal(false);
        setIsFixed(false);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const handleDiscountTypeChange = (e) => {
        setDiscountType(e.target.value);
        form.setFieldsValue({
            cashback_point_percent: null,
            cashback_point_price: null,
        });
    };

    const handleScopeChange = (e) => {
        const selectedScope = e.target.value;
        setDiscountScope(selectedScope);

        if (selectedScope === 'all') {
            setCategories([]);
            setProducts([]);
        } else if (selectedScope === 'category') {
            setProducts([]);
            setCategories(categoriesAll);
        } else if (selectedScope === 'product') {
            setCategories([]);
            setProducts(productsAll);
        } else if (selectedScope === 'outlet') {
            setOutlets(outletsAll);
        }
    };

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Member Cashback</h3>
            <Row>
                <Col span={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Search
                        disabled={loadingBtn}
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button disabled={loadingBtn} style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Form Member Cashback" open={isModal} onOk={form.submit} onCancel={() => closeModal()} okText="Simpan" >
                <Form form={form} name="form_discount" layout="vertical" onFinish={onFinish}>

                    <Form.Item
                        name="name"
                        label="Nama Cashback"
                        rules={[{ required: true, message: 'Nama Cashback tidak boleh kosong!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item name="description" label="Deskripsi">
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item
                        name="outlet"
                        label="Pilih Outlet"
                    >
                        <Select
                            showSearch
                            mode="multiple"
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                const label = option?.label ?? '';  // Pastikan label tidak undefined
                                return label.toLowerCase().includes(input.toLowerCase());
                            }}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            placeholder="Pilih Outlet">
                            {outlets.map((value) => (
                                <Select.Option key={value.outlet_id} value={value.outlet_id} label={value.outlet_name}>
                                    {value.outlet_name}
                                </Select.Option>
                            ))}
                        </Select>

                    </Form.Item>

                    <Form.Item label="Cakupan Cashback">
                        <Radio.Group onChange={handleScopeChange} value={discountScope}>
                            <Radio value="all">Global</Radio>
                            <Radio value="category">Kategori</Radio>
                            <Radio value="product">Produk</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {discountScope === 'category' && (
                        <Form.Item
                            name="category"
                            label="Pilih Kategori"
                            rules={[{ required: true, message: 'Pilih setidaknya satu kategori!' }]}
                        >
                            <Select
                                showSearch
                                mode="multiple"
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    const label = option?.label ?? '';  // Pastikan label tidak undefined
                                    return label.toLowerCase().includes(input.toLowerCase());
                                }}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                placeholder="Pilih Kategori">
                                {categories.map((category) => (
                                    <Select.Option key={category.category_id} value={category.category_id} label={category.category_name}>
                                        {category.category_name}
                                    </Select.Option>
                                ))}
                            </Select>

                        </Form.Item>
                    )}

                    {discountScope === 'product' && (
                        <Form.Item
                            name="product"
                            label="Pilih Produk"
                            rules={[{ required: true, message: 'Pilih setidaknya satu produk!' }]}
                        >
                            <Select
                                showSearch
                                mode="multiple"
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    const label = option?.label ?? '';  // Pastikan label tidak undefined
                                    return label.toLowerCase().includes(input.toLowerCase());
                                }}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                placeholder="Pilih Produk">
                                {products.map((product) => (
                                    <Select.Option key={product.product_id} value={product.product_id} label={product.product_name}>
                                        {product.product_name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    <Form.Item label="Tipe Cashback Cashback Point">
                        <Radio.Group onChange={handleDiscountTypeChange} value={discountType}>
                            <Radio value="percent">Cashback Persen (%)</Radio>
                            <Radio value="price">Cashback Nominal (Harga)</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {discountType === 'percent' && (
                        <Form.Item
                            name="cashback_point_percent"
                            label="Cashback Persen (%)"
                            rules={[{ required: true, message: 'Cashback Persen (%) tidak boleh kosong!' }]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    )}

                    {discountType === 'price' && (
                        <Form.Item
                            name="cashback_point_price"
                            label="Cashback Point (Rp)"
                            rules={[{ required: true, message: 'Cashback Point (Rp) tidak boleh kosong!' }]}
                        >
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    )}

                    <Form.Item name="start_date" label="Tanggal Mulai" rules={[{ required: !isFixed, message: 'Tanggal Mulai tidak boleh kosong!' }]}>
                        <Input type="date" disabled={isFixed} />
                    </Form.Item>

                    <Form.Item name="end_date" label="Tanggal Akhir" rules={[{ required: !isFixed, message: 'Tanggal Akhir tidak boleh kosong!' }]}>
                        <Input type="date" disabled={isFixed} />
                    </Form.Item>

                    <Form.Item name="start_time" label="Waktu Mulai" rules={[{ required: !isFixed, message: 'Waktu Mulai tidak boleh kosong!' }]}>
                        <Input type="time" disabled={isFixed} />
                    </Form.Item>

                    <Form.Item name="end_time" label="Waktu Akhir" rules={[{ required: !isFixed, message: 'Waktu Akhir tidak boleh kosong!' }]}>
                        <Input type="time" disabled={isFixed} />
                    </Form.Item>

                    <Form.Item
                        name="is_status_fixed"
                        label="Status Tetap"
                        valuePropName="checked"
                    >
                        <Checkbox checked={isFixed} onChange={() => setIsFixed(!isFixed)}>
                            Permanent / Tetap
                        </Checkbox>
                    </Form.Item>
                </Form>

            </Modal>
        </>
    );
}

export default ListMemberDisc;