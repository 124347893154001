import { Alert, Button, Checkbox, Col, Form, Input, InputNumber, Modal, Radio, Row, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
import { currencyFormat } from '../../Utils/formatCurrency';

function ShopMinPromo() {
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');

    const [isFixed, setIsFixed] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [form] = Form.useForm();
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // Parameter
    const titlePage = 'Promo Min Belanja';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Percent',
            dataIndex: 'percent',
            key: 'percent',
            render: (text, record) => {
                if (record.percent !== null)
                    return record.percent+'%';
                else
                    return '-';
            }
        },
        {
            title: 'Harga',
            dataIndex: 'price',
            key: 'price',
            render: (text, record) => {
                if (record.price !== null)
                    return currencyFormat(record.price);
                else
                    return '-';
            }
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('edit', record)}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi('/shopminpromo?search=' + search + '&page=' + page.pagination.current);
    }, [JSON.stringify(page), isAlert]);

    const getApi = (url) => {
        setLoading(true);
        const dataReq = {
            method: 'GET',
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            },
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setData(res.data.rows);
                setPage({
                    ...page,
                    pagination: {
                        ...page.pagination,
                        total: res.data.count,
                    },
                });
                setLoading(false);
                setLoadingBtn(false);
            } else {
                setLoading(false);
                setLoadingBtn(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            setLoadingBtn(false);
            console.log(e.message);
        });
    };

    const postApi = (method, values) => {
        setLoading(true);
        setIsAlert(false);
        let url = '/shopminpromo';
        if (method === 'DELETE' || method === 'PUT') {
            url = '/shopminpromo/' + values.shopping_promotion_min_id;
        }
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            },
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setdataModal(null);
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        });
    };

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Do you want to delete this item?',
            onOk: () => postApi('DELETE', v),
        });
    };

    const openModal = (type, data) => {
        if (type === 'edit') {
            setdataModal(data);
            var ev = {
                title: data.title,
                description: data.description,
                start_date: data.start_date,
                end_date: data.end_date,
                start_time: data.start_time,
                end_time: data.end_time,
                is_fixed: data.is_fixed,
                min_shopping_price: data.min_shopping_price
            }
            if (data.is_percent === true) {
                ev.percent = data.percent;
                ev.promo_type = 'percent';
                setSelectedOption('percent')
            } else if (data.is_price === true) {
                ev.price = data.price;
                ev.promo_type = 'price';
                setSelectedOption('price')
            }
            form.setFieldsValue(ev);
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    };

    const onFinish = (ev) => {
        if (ev.promo_type === 'percent') {
            ev.is_percent = true;
            ev.is_price = false;
        } else {
            ev.is_percent = false;
            ev.is_price = true;
        }
        if (isFixed === true) {
            ev.is_fixed = ev.is_fixed;
            ev.start_date = null;
            ev.end_date = null;
            ev.start_time = null;
            ev.end_time = null;
        } else {
            ev.start_date = ev.start_date;
            ev.end_date = ev.end_date;
            ev.start_time = ev.start_time;
            ev.end_time = ev.end_time;
        }
        if (dataModal != null) {
            const data = {
                shopping_promotion_min_id: dataModal.shopping_promotion_min_id,
                title: ev.title,
                description: ev.description,
                percent: ev.percent,
                start_date: ev.start_date,
                end_date: ev.end_date,
                start_time: ev.start_time,
                end_time: ev.end_time,
                min_shopping_price: ev.min_shopping_price,
                is_percent: ev.is_percent,
                is_price: ev.is_price,
                is_fixed: ev.is_fixed,
            };
            postApi('PUT', data);
        } else {
            postApi('POST', ev);
        }
    };

    const closeModal = () => {
        setdataModal(null);
        setIsModal(false);
        form.resetFields();
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    };

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Promo Cashback Min Belanja</h3>
            <Row>
                <Col span={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Search
                        disabled={loadingBtn}
                        placeholder="Search"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button disabled={loadingBtn} style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Promo Form" open={isModal} onOk={form.submit} onCancel={() => closeModal()} okText="Save">
                <Form form={form} name="form_shop_min" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="title"
                        label="Judul"
                        rules={[{ required: true, message: 'Judul harus diisi!' }]}
                    >
                        <Input placeholder="Masukkan judul promo" />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Deskripsi"
                        rules={[{ required: true, message: 'Deskripsi harus diisi!' }]}
                    >
                        <Input.TextArea placeholder="Masukkan deskripsi promo" />
                    </Form.Item>

                    <Form.Item name="min_shopping_price" label="Harga Min. Belanja"
                        rules={[{ required: true, message: 'Harga Min. Belanja harus diisi!' }]}>
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100%' }}
                            placeholder="Masukkan harga minimal belanja"
                        />
                    </Form.Item>

                    <Form.Item name="promo_type" label="Pilih Tipe Promo">
                        <Radio.Group onChange={(e) => setSelectedOption(e.target.value)} value={selectedOption}>
                            <Radio value="percent">Gunakan Persentase</Radio>
                            <Radio value="price">Gunakan Harga</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {/* Conditionally render inputs based on selected option */}
                    {selectedOption === 'percent' && (
                        <Form.Item name="percent" label="Persentase Cashback (%)">
                            <Input placeholder="Masukkan persentase cashback" />
                        </Form.Item>
                    )}
                    {selectedOption === 'price' && (
                        <Form.Item name="price" label="Harga Cashback (Rp)">
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                style={{ width: '100%' }}
                                placeholder="Masukkan Harga cashback"
                            />
                        </Form.Item>
                    )}

                    {/* Checkbox for 'Promo Tetap' */}
                    <Form.Item name="is_fixed" label="Promo Tetap" valuePropName="checked">
                        <Checkbox onChange={(e) => setIsFixed(e.target.checked)}>Promo Tetap</Checkbox>
                    </Form.Item>

                    {/* Conditionally render date and time fields based on 'Promo Tetap' */}
                    {!isFixed && (
                        <>
                            <Form.Item name="start_date" label="Tanggal Mulai">
                                <Input type="date" disabled={isFixed} placeholder="Masukkan tanggal mulai promo" />
                            </Form.Item>
                            <Form.Item name="end_date" label="Tanggal Berakhir">
                                <Input type="date" disabled={isFixed} placeholder="Masukkan tanggal berakhir promo" />
                            </Form.Item>
                            <Form.Item name="start_time" label="Waktu Mulai">
                                <Input type="time" disabled={isFixed} placeholder="Masukkan waktu mulai promo" />
                            </Form.Item>
                            <Form.Item name="end_time" label="Waktu Berakhir">
                                <Input type="time" disabled={isFixed} placeholder="Masukkan waktu berakhir promo" />
                            </Form.Item>
                        </>
                    )}

                </Form>
            </Modal>
        </>
    );
}

export default ShopMinPromo;
