import { Button, Col, Row, Select, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { currencyFormat } from '../../Utils/formatCurrency';

function SetOutletProduct() {
    const token = useSelector((state) => state.user.token);
    //LIBRARY
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [outlet, setOutlet] = useState([]);
    const [outletVal, setOutletVal] = useState(null);

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    // //Parameter
    const titlePage = 'Produk';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Outlet',
            dataIndex: 'outlet',
            key: "outlet",
            
            render: (id, record, index) => {
                if (record.outlet_product != null)
                    if (record.outlet_product.outlet != null)
                        return record.outlet_product.outlet.outlet_name;
                    else
                        return null;
                else
                    return null;
            },
        },
        {
            title: 'Kode Barang',
            dataIndex: 'product_code',
            key: "product_code",
            
            render: (id, record, index) => {
                if (record.outlet_product != null)
                    if (record.outlet_product.product != null)
                        return record.outlet_product.product.product_code;
                    else
                        return null;
                else
                    return null;
            },
        },
        {
            title: 'Nama Barang',
            dataIndex: 'product_name',
            key: "product_name",
            
            render: (id, record, index) => {
                if (record.outlet_product != null)
                    if (record.outlet_product.product != null)
                        return record.outlet_product.product.product_name;
                    else
                        return null;
                else
                    return null;
            },
        },
        {
            title: 'Varian',
            dataIndex: 'variant',
            key: "variant",
            
            render: (id, record, index) => {
                if (record.variant_product != null)
                    return record.variant_product.variant_name;
                else
                    return null;
            },
        },
        {
            title: 'Stok',
            dataIndex: 'stock',
            key: "stock",
            
            render: (text, record) => {
                return record.stock;
            }
        },
        {
            title: 'Harga Barang',
            dataIndex: 'price',
            key: "price",
            
            render: (text, record) => {
                return currencyFormat(record.price);
            }
        },
        {
            title: 'Diskon',
            dataIndex: 'discount',
            key: "discount",
            
            render: (text, record) => {
                if (record.discount != null)
                    if (Number(record.discount.discount_percent_2) > 0)
                        return record.discount.discount_percent + '%+' + record.discount.discount_percent_2 + '%';
                    else
                        return record.discount.discount_percent + '%';
                else
                    return null;

            }
        },
        {
            title: 'Harga Diskon',
            dataIndex: 'price_after_discount',
            key: "price_after_discount",
            
            render: (text, record) => {
                if (record.discount != null)
                    return currencyFormat(record.discount.price_after_discount);
                else
                    return null;

            }
        },
        {
            title: 'Gudang',
            dataIndex: 'warehouse',
            key: "warehouse",
            
            render: (id, record, index) => {
                if (record.outlet_product != null)
                    if (record.outlet_product.warehouse != null)
                        return record.outlet_product.warehouse.warehouse_name;
                    else
                        return null;
                else
                    return null;
            },
        },
    ];

    useEffect(() => {
        var outletId = outletVal;
        var url = '/outletproduct';
        if (outletId !== null) {
            url = '/outletproduct?outletId=' + outletId;
        }
        getApi("GET", url, 'outletproduct');
        getApi("GET", '/outlet?nopage=true', 'outlet');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), outletVal]);


    const getApi = (method, url, type) => {
        setLoading(true);
        const dataReq = {
            method,
            url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'outletproduct':

                        setData(res.data.rows);
                        break;
                    case 'outlet':
                        var o = [];
                        for (let i = 0; i < res.data.length; i++) {
                            o.push({
                                value: res.data[i].outlet_id,
                                label: res.data[i].outlet_name
                            });
                        }
                        setOutlet(o);
                        setLoading(false);
                        break;
                    default:
                        setLoading(false);
                        break;
                }
            } else {
                setLoading(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };


    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            <h3>Persediaan Toko Produk</h3>
            <Row>
                <Col span={10}>
                    <Row>
                        <Select
                            placeholder="Pilih Outlet"
                            onChange={(e) => setOutletVal(e)}
                            options={outlet}
                            style={{
                                width: '100%',
                                marginBottom: 10
                            }}
                        />
                    </Row>
                </Col>
                <Col span={2}>
                    <Button disabled={outletVal !== null ? false : true} type="primary" style={{ marginLeft: 10 }} onClick={() => navigate('/setProdukVariantOutlet', { state: { item: outletVal } })}>
                        <b style={{ marginLeft: 5 }}>Ubah Data</b>
                    </Button>
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button block type="primary" style={{ width: 150, marginBottom: 10 }} onClick={() => navigate('/setProdukVariantOutlet')}>
                        <b style={{ marginLeft: 5 }}>Tambah Data</b>
                    </Button>
                </Col>
            </Row>

            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    );
}

export default SetOutletProduct;