import { Button, Card, Col, DatePicker, Descriptions, Divider, Modal, Row, Select, Spin, Typography, Table, Alert } from "antd";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { apiCall } from "../../API/RestApi";
import { currencyFormat } from "../../Utils/formatCurrency";
import { useReactToPrint } from 'react-to-print';
import { useSelector } from "react-redux";
import moment from 'moment';
import { ExclamationCircleFilled, FilePdfOutlined, PrinterOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { dateFormat } from "../../Utils/formatDate";
import { modalError, modalSuccess } from "../../Component/Modal/ModalLayout";
import { formatNumber } from "../../Utils/formatNumber";
import { exportToExcelTransaction } from "../../Utils/Excel/TransactionExportExcel";
import Search from "antd/es/input/Search";
import { writePrintHistory } from "../../Utils/PrintThermal";
const { Text } = Typography;
const { RangePicker } = DatePicker;

const { confirm } = Modal;

function ListTransactionReport() {
    const componentRef = useRef();
    const token = useSelector((state) => state.user.token);
    const serialPort = useSelector(state => state.print.port);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isMember, setIsMember] = useState(null);
    const [dataModal, setDataModal] = useState();
    const [dataEdit, setDataEdit] = useState();

    const [outlet, setOutlet] = useState([]);
    const [search, setSearch] = useState(null);

    const [valueOutlet, setValueOutlet] = useState();
    const [valueDate, setValueDate] = useState();
    const [valueStatusAccurate, setValueStatusAccurate] = useState(null);

    const [modalReport, setModalReport] = useState(false);
    const [modalCheckProduct, setModalCheckProduct] = useState(false);
    const [modalPrint, setModalPrint] = useState(false);

    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        sorter: undefined
    });
    const titlePage = 'Laporan Transaksi';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Kode Transaksi',
            dataIndex: 'transaction_code',
            key: "transaction_code",

            render: (id, record, index) => {
                return record.transaction_code;
            },
        },
        {
            title: 'Tanggal',
            dataIndex: 'created_at',
            key: "created_at",

            render: (id, record, index) => { return dateFormat(record.created_at); },
        },
        {
            title: 'Nama Member',
            dataIndex: 'customer_name',
            key: "customer_name",

            render: (id, record, index) => {
                if (record.customer !== null) {
                    return record.customer.name;
                } else {
                    return null;
                }
            },
        },
        {
            title: 'Outlet',
            dataIndex: 'outlet_name',
            key: "outlet_name",

            render: (id, record, index) => { return record.outlet.outlet_name; },
        },
        {
            title: 'Gudang',
            dataIndex: 'warehouse',
            key: "warehouse",

            render: (id, record, index) => {
                if (record.transaction_products[0].warehouse !== null)
                    return record.transaction_products[0].warehouse.warehouse_name;
                else
                    return '-';
            },
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: "qty",
            render: (id, record, index) => {
                var qty = 0;
                if (record.transaction_products.length > 0) {
                    for (let i = 0; i < record.transaction_products.length; i++) {
                        qty += Number(record.transaction_products[i].qty);
                    }
                }
                return qty;
            },
        },
        {
            title: 'Harga',
            dataIndex: 'grand_total',
            key: "grand_total",

            render: (id, record, index) => {
                return currencyFormat(record.grand_total);
            },
        },
        {
            title: 'Point',
            dataIndex: 'point',
            key: "point",

            render: (id, record, index) => {
                if (record.point !== null) {
                    return formatNumber(record.point.point) + ' point';
                } else {
                    return '0 point';
                }
            },
        },
        {
            title: 'Voucher',
            dataIndex: 'voucher',
            key: "voucher",

            render: (id, record, index) => {
                if(record.voucher){
                    return currencyFormat(record.voucher.discount_price);
                }else{
                    return currencyFormat(record.voucher_price);
                }
            },
        },
        {
            title: 'Pembayaran',
            dataIndex: 'device_payment',
            key: "device_payment",

            render: (id, record, index) => {
                if (record.payment_method === 'tunai') {
                    return 'TUNAI';
                } else {
                    return record.device_payment;
                }
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {
                return (
                    <>
                        <Button style={{ marginRight: 5 }} onClick={() => TryPrint(record)}>
                            <PrinterOutlined />
                        </Button>
                        <Button type="default" style={{ margin: 5 }} onClick={() => downloadInvoicePDF(record.transaction_code)}>
                            <FilePdfOutlined />
                        </Button>
                        <Button type="primary" style={{ margin: 5 }} onClick={() => detail(record)}>
                            <UnorderedListOutlined />
                        </Button>
                        <Button
                            disabled={record.status_send}
                            style={{ margin: 5 }} onClick={() => confirm({
                                title: 'Notice',
                                icon: <ExclamationCircleFilled />,
                                content: 'Anda ingin mengirim faktur ke accurate',
                                onOk() {
                                    sendToAccurate(record, index);
                                },
                                onCancel() {
                                    console.log('Cancel');
                                },
                            })}>
                            <b style={{ marginLeft: 5 }}>{record.status_send === true ? 'Telah Terkirim' : 'Kirim Accurate'}</b>
                        </Button >
                    </>
                );
            },
        },
    ];

    const ProductColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Nama Produk',
            dataIndex: 'product_name',
            key: "product_name",
            render: (id, record, index) => {
                return record.product.product_name
            },
        },
        {
            title: 'Harga',
            dataIndex: 'price',
            key: "price",
            render: (id, record, index) => {
                if (record.discount !== null)
                    return currencyFormat(record.discount.price_after_discount + record.discount.discount_price);
                else
                    return currencyFormat(Number(record.price));
            },
        },
        {
            title: 'Diskon',
            dataIndex: 'discount_percent',
            key: "discount_percent",
            render: (id, record, index) => {
                if (record.discount !== null)
                    return Number(record.discount.discount_percent) + '%';
                else
                    return 0;
            },
        },
        {
            title: 'Harga Jual',
            dataIndex: 'price_after_discount',
            key: "price_after_discount",
            render: (id, record, index) => {
                if (record.discount !== null)
                    return currencyFormat(record.discount.price_after_discount);
                else
                    return 0;
            },
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: "qty",
            render: (id, record, index) => {
                return record.qty;
            },
        },
        {
            title: 'Total',
            dataIndex: 'subtotal',
            key: "subtotal",
            render: (id, record, index) => {
                return currencyFormat(record.subtotal);
            },
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (text, record, index) => {
                if (record.is_edit === true)
                    return (
                        <>
                            <Button style={{ marginRight: 5 }} onClick={() => checkProductData(record)}>
                                Cek Edit
                            </Button>
                        </>
                    );
                else
                    return '';
            },
        },
    ];

    const checkProductData = (ev) => {
        setModalCheckProduct(true);
        setDataEdit(ev);
    }

    useEffect(() => {
        var vOutlet = 'outletId=' + valueOutlet;
        var isSendAccurate = '';
        if (valueStatusAccurate !== null) {
            isSendAccurate = 'isSendAccurate=' + valueStatusAccurate;
        }
        var vDate = '';
        if (valueDate !== null && valueDate !== undefined) {
            vDate = 'startDate=' + valueDate[0] + '&endDate=' + valueDate[1];
        }
        var url = '/transaction?page=' + page.pagination.current;

        if (isSendAccurate !== undefined) {
            url += '&' + isSendAccurate;
        }

        if (valueOutlet !== undefined) {
            url += '&' + vOutlet;
        }

        if (valueDate !== undefined) {
            url += '&' + vDate;
        }

        if (search !== null) {
            url += '&search=' + search;
        }

        if (isMember !== null) {
            url += '&isMember=' + isMember;
        }

        if (page.sorter !== undefined) {
            var order = 'desc';
            if (page.sorter.order === 'ascend') {
                order = 'asc';
            }
            url += '&sorter=' + page.sorter.field + '&order=' + order;
        }
        getApi("GET", url, 'transaction');
        if (outlet.length === 0) {
            getApi('GET', '/outlet?nopage=true&isInput=true', 'outlet');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page)]);

    const getApi = (method, url, type, dataRequest = null, index = null) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: dataRequest,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                if (type === 'transaction') {
                    setData(res.data.rows);
                    setPage({
                        ...page,
                        pagination: {
                            ...page.pagination,
                            total: res.data.count
                        }
                    });
                    setLoading(false);
                } else if (type === 'transactionnopage') {
                    exportToExcelTransaction(res.data.rows);
                    modalSuccess('Success');
                    setIsLoading(false);
                    setLoading(false);
                } else if (type === 'outlet') {
                    var o = [];
                    for (let i = 0; i < res.data.length; i++) {
                        o = [...o, {
                            value: res.data[i].outlet_id,
                            label: res.data[i].outlet_name
                        }];
                    }
                    setOutlet(o);
                } else if (type === 'excel') {
                    window.location.replace(res.path);
                    setLoading(false);
                } else if (type === 'send_to_accurate') {
                    if (res.respon === true) {
                        setPage({
                            pagination: {
                                current: page.pagination.current,
                                pageSize: 10,
                            },
                        });
                        // var url = '/transaction?page=' + page.pagination.current;
                        // if (valueDate !== null && valueDate !== undefined) {
                        //     url += '&startDate=' + valueDate[0] + '&endDate=' + valueDate[1];
                        // }
                        // if (valueOutlet !== '' && valueOutlet !== null && valueOutlet !== undefined) {
                        //     url += '&outletId=' + valueOutlet;
                        // }
                        // getApi("GET", url, 'transaction');
                        data[index].status_send = true;
                        setData(data);
                        modalSuccess('Berhasil di Upload ke Accurate :)');
                        setLoading(false);
                    } else {
                        modalError(res.message);
                        setLoading(false);
                    }
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const TryPrint = (item) => {
        var data_print = {
            product: item,
            customer: item.customer,
            cashier: item.user,
            outlet: item.outlet,
            payment_method: item.payment_method
        }
        if (serialPort !== null) {
            writePrintHistory(serialPort, data_print);
        }
    }

    const sendToAccurate = (ev, index) => {
        const date = moment(ev.created_at);
        const transDate = date.format('DD/MM/YYYY');

        const data = {
            outlet_id: ev.outlet_id,
            outlet_code: ev.outlet.outlet_code,
            outlet_name: ev.outlet.outlet_name,
            branch_name: ev.outlet.branch_name,
            transaction_id: ev.transaction_id,
            transaction_code: ev.transaction_code,
            voucher_price: ev.voucher_price,
            point_redeem: ev.point_redeem,
            payment_method: ev.payment_method,
            device_payment: ev.device_payment,
            trans_date: transDate,
            grand_total: ev.grand_total,
            notes: ev.notes,
            akun_no: ev.akun_no,
            item: []
        };

        ev.transaction_products.forEach(item => {
            if (item.is_deleted != true) {
                let total_discount = 0;
                let price = item.price;
                if (item.discount !== null) {
                    if (item.discount.price_after_discount !== null && item.discount.discount_price !== null) {
                        price = Number(item.discount.price_after_discount) + Number(item.discount.discount_price);
                        total_discount = Number(item.qty) * Number(item.discount.discount_price);
                    }
                }
                data.item.push({
                    product_id: Number(item.product.product_id),
                    product_code: item.product.product_code,
                    variant_product_id: Number(item.variant_product.variant_product_id),
                    qty: item.qty,
                    product_name: item.product.product_name,
                    price: price,
                    subtotal: Number(item.subtotal),
                    warehouse_id: item.warehouse.warehouse_id,
                    warehouse_name: item.warehouse.warehouse_name,
                    total_discount: total_discount
                });
            }
        });
        getApi('POST', '/transaction/sendaccurate', 'send_to_accurate', data, index);
    };

    // const downloadExcel = (ev) => {
    //     getApi('POST', '/transaction/fakturpenjualan?invoice=' + ev.transaction_code, 'excel');
    // }

    const detail = (ev) => {
        setDataModal(ev);
        setModalReport(true);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const findSearch = () => {
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    // const openModal = (ev) => {
    //     setModalPrint(true);
    // }

    const goPrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const exportData = () => {
        setIsLoading(true);
        var url = '/transaction?nopage=true';
        if (valueDate !== null && valueDate !== undefined) {
            url += '&startDate=' + valueDate[0] + '&endDate=' + valueDate[1];
        }
        if (valueOutlet !== '' && valueOutlet !== null && valueOutlet !== undefined) {
            url += '&outletId=' + valueOutlet;
        }
        if (valueStatusAccurate !== '' && valueStatusAccurate !== null && valueStatusAccurate !== undefined) {
            url += '&isSendAccurate=' + valueStatusAccurate;
        }
        getApi('GET', url, 'transactionnopage');
    }


    const downloadInvoicePDF = async (transaction_code) => {
        const downloadUrl = `https://pos-api.boldestore.com/api/sendreceipt?transaction_code=${transaction_code}`;
        // const downloadUrl = `http://localhost:8010/api/sendreceipt?transaction_code=${transaction_code}`;
        window.open(downloadUrl, '_blank');
    }

    return (
        <div>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            <h3>Laporan Transaksi</h3>
            <Spin spinning={isLoading}>
                <Row gutter={[12, 12]} style={{ marginBottom: 10 }}>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <Select
                            mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            placeholder="Pilih Outlet"
                            onChange={setValueOutlet}
                            options={outlet}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <RangePicker
                            style={{ width: '100%' }}
                            onChange={(ev, date) => setValueDate(date)}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={4}>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Status Accurate"
                            onChange={setValueStatusAccurate}
                            options={[
                                { label: 'Semua', value: null },
                                { label: 'Terkirim', value: true },
                                { label: 'Belum Terkirim', value: false },
                            ]}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8}>
                        <Search
                            placeholder="Pencarian"
                            allowClear
                            size="medium"
                            style={{ width: '100%' }}
                            onChange={(ev) => setSearch(ev.target.value)}
                            onSearch={findSearch}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={4}>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Status Member"
                            onChange={(ev) => setIsMember(ev)}
                            options={[
                                { label: 'Semua', value: null },
                                { label: 'Membership', value: true },
                                { label: 'Non Membership', value: false },
                            ]}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} style={{ display: 'flex', gap: 16 }}>
                        <Button type="primary" onClick={findSearch} style={{ flex: 1 }}>
                            <b>Cari</b>
                        </Button>
                        <Button type="default" onClick={exportData} style={{ flex: 1 }}>
                            Export to Excel
                        </Button>
                    </Col>
                </Row>
                <Table
                    size="middle"
                    columns={columns}
                    dataSource={data}
                    pagination={page.pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />

                <Modal title="Cetak Data" width={500} open={modalPrint} footer={null} onCancel={() => setModalPrint(false)}>
                    <Button style={{ marginBottom: 10, marginLeft: 10 }} onClick={() => goPrint(true)}>
                        <b style={{ marginLeft: 5 }}>Cetak Docs</b>
                    </Button>
                    <Button style={{ marginBottom: 10, marginLeft: 10 }} onClick={() => goPrint(true)}>
                        <b style={{ marginLeft: 5 }}>Cetak Excel</b>
                    </Button>
                </Modal>
                <Modal
                    title="Detail Transaksi"
                    width={1000}
                    open={modalReport}
                    footer={null}
                    onCancel={() => setModalReport(false)}
                >
                    {
                        dataModal && (
                            <>
                                <Descriptions
                                    bordered
                                    size="small"
                                    column={2}
                                    labelStyle={{ fontSize: 12, fontWeight: 'bold' }}
                                    contentStyle={{ fontSize: 12 }}
                                >
                                    <Descriptions.Item label="Kode Transaksi">
                                        {dataModal.transaction_code}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Nama Outlet">
                                        {dataModal.outlet.outlet_name}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Kode Outlet">
                                        {dataModal.outlet.outlet_code}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Total Harga" span={2}>
                                        <span style={{ color: "#DC143C", fontWeight: "bold" }}>
                                            {currencyFormat(Number(dataModal.grand_total))}
                                        </span>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Point">
                                        {dataModal.point !== null ? formatNumber(dataModal.point.point) : 0} Point
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Point Ditukar">
                                        {dataModal.point !== null ? formatNumber(dataModal.point_redeem) : 0} Point
                                    </Descriptions.Item>
                                    {dataModal.voucher && <Descriptions.Item label="Kode Voucher">
                                        {dataModal.voucher !== null ? dataModal.voucher.voucher_code : '-'}
                                    </Descriptions.Item>}
                                    {dataModal.voucher && <Descriptions.Item label="Voucher">
                                        {dataModal.voucher !== null ? formatNumber(dataModal.voucher.discount_price) : '-'}
                                    </Descriptions.Item>}
                                </Descriptions>
                                <Divider />
                                <h3>Voucher </h3>
                                {
                                    dataModal.transaction_vouchers.map((key, i) => (
                                        <Descriptions
                                            key={i}
                                            bordered
                                            size="small"
                                            column={2}
                                            layout="vertical" // Membantu membuat tampilan lebih rapi
                                            labelStyle={{ fontSize: 12, fontWeight: 'bold', background: "#f5f5f5", textAlign: "center", padding: "8px" }}
                                            contentStyle={{ fontSize: 12, textAlign: "center", padding: "8px" }}
                                            style={{ marginBottom: 10 }} // Memberikan jarak antar voucher
                                        >
                                            <Descriptions.Item label="Kode Voucher">
                                                {key.voucher?.voucher_code ?? '-'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Voucher">
                                                {key.voucher.is_discount === true ? key.voucher.discount_percent + '%' : formatNumber(key.voucher.discount_price)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    ))
                                }



                                <h3 style={{ marginTop: 20, marginBottom: 10 }}>Daftar Produk</h3>

                                <Table
                                    size="middle"
                                    columns={ProductColumn}
                                    loading={loading}
                                    dataSource={dataModal.transaction_products}
                                    pagination={false}
                                    bordered
                                />
                            </>
                        )
                    }
                </Modal>

                <Modal
                    title="Informasi Produk"
                    width={500}
                    open={modalCheckProduct}
                    footer={null}
                    onCancel={() => setModalCheckProduct(false)}
                >
                    {dataEdit !== null && dataEdit !== undefined && (
                        <>
                            {/* Display Informasi Edit as an Alert */}
                            {dataEdit.desc_edit && (
                                <Alert
                                    message="Informasi Edit"
                                    description={dataEdit.desc_edit}
                                    type="info"
                                    showIcon
                                    style={{ marginBottom: 20 }}
                                />
                            )}

                            <Row gutter={[16, 16]}>
                                <Divider />

                                {/* Product Name */}
                                <Col span={12}>
                                    <Text strong>Nama Produk:</Text>
                                </Col>
                                <Col span={12}>
                                    <Text>{dataEdit.product.product_name}</Text>
                                </Col>

                                <Divider />

                                {/* Discount Details */}
                                {dataEdit.discount !== null && (
                                    <>
                                        <Col span={12}>
                                            <Text strong>Harga Normal:</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text>{currencyFormat(dataEdit.discount.price_after_discount + dataEdit.discount.discount_price)}</Text>
                                        </Col>

                                        <Col span={12}>
                                            <Text strong>Diskon:</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text>{`${Number(dataEdit.discount.discount_percent)}%`}</Text>
                                        </Col>

                                        <Col span={12}>
                                            <Text strong>Harga Jual:</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text>{currencyFormat(dataEdit.discount.price_after_discount)}</Text>
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </>
                    )}
                </Modal>
            </Spin>
        </div>
    );
}


export default ListTransactionReport;