
import { Alert, Button, Col, Form, Input, Modal, Row, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { SyncOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';

function ListPriceCategory() {
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [form] = Form.useForm();
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Kategori Harga';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
             sorter: false,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: "name",
             sorter: false,
        },
        {
            title: 'Deskripsi',
            dataIndex: 'description',
            key: "description",
             sorter: false,
        },
        // {
        //     title: 'Tanggal Buat',
        //     dataIndex: 'created_at',
        //     key: "created_at",
        //      sorter: false,
        // },
        // {
        //     title: 'Action',
        //     dataIndex: 'action',
        //     key: 'action',
        //     render: (text, record) => {
        //         return (
        //             <>
        //                 <Button type="primary" danger onClick={() => modalDeleteData(record)}>
        //                     <DeleteOutlined />
        //                     <b style={{ marginLeft: 5 }}>Hapus</b>
        //                 </Button>
        //             </>
        //         );
        //     },
        // },
    ];

    useEffect(() => {
        getApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = () => {
        setLoading(true);
        const dataReq = {
            method: "GET",
            url: '/pricecategory?search=' + search + '&page=' + page.pagination.current,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setData(res.data.rows);
                setPage({
                    ...page,
                    pagination: {
                        ...page.pagination,
                        total: res.data.count
                    }
                });
                setLoading(false);
                setLoadingBtn(false);
            } else {
                setLoading(false);
                setLoadingBtn(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            setLoadingBtn(false);
            console.log(e.message);
        })
    }



    const postApi = (method, values) => {
        setLoading(true);
        setIsAlert(false);
        var url = '/pricecategory';
        if (method === 'DELETE') {
            url = '/pricecategory/' + values.category_id;
        }
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    // const modalDeleteData = (v) => {
    //     Modal.confirm({
    //         title: 'NOTICE',
    //         content: 'Anda ingin menghapus data ini?',
    //         onOk: () => postApi('DELETE', v),
    //     });
    // }

    const openModal = (type, data) => {
        if (type === 'edit') {
            form.setFieldsValue({
                name: data.name,
                description: data.description
            })
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    const onFinish = (ev) => {
        postApi('POST', ev);
    }

    const closeModal = () => {
        setIsModal(false);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const syncProductAccurate = () => {
        setLoadingBtn(true);
        setLoading(true);
        getApi('/accurate/loadpricecategory?page=1&pageSize=100');
    }

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Kategori Harga</h3>
            <Row style={{ marginBottom: 10 }}>
                <Col span={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Button style={{ marginBottom: 10, marginRight: 10 }} type="primary" onClick={() => syncProductAccurate()}>
                        {
                            loadingBtn === true ?
                                <LoadingOutlined />
                                :
                                <SyncOutlined />
                        }
                        Sync Accurate
                    </Button>
                    <Search
                        disabled={loadingBtn}
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button disabled={loadingBtn} style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah Kategori</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Form Kategori" open={isModal} onOk={form.submit} onCancel={() => closeModal()} okText="Simpan" >
                <Form form={form} name="form_category" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="name"
                        label="Nama Kategori"
                        rules={[
                            {
                                required: true,
                                message: 'Nama Kategori kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Deksripsi"
                        rules={[
                            {
                                required: false,
                                message: 'Deskripsi kosong!'
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default ListPriceCategory;